import { AnalysisFilterGroupModel, AnalysisFilterModel, AnalysisAppliedFiltersModel } from 'pages/analysis/models';
import { AnalysisFiltersService } from 'components/common/services/analysis-filters/analysis-filters.service';
import { AppliedFilterValuesModel } from '@ic/component-lib/src/components/modules/applied-filters/models';
import { ChangeDetectorRef, Component, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { cloneDeep, isString, isPlainObject, find, clone, assign, sortBy, pick, isEqual, uniqBy, isUndefined } from 'lodash';
import { Subscription, forkJoin } from 'rxjs';
import { EnvironmentService } from 'components/app/services/environment/environment.service';
import { FilterOptionModel } from 'pages/tab-report/interfaces';
import { tap } from 'rxjs/operators';
import {
  AnalysisAppliedFiltersService
} from 'components/common/services/analysis-applied-filters/analysis-applied-filters.service';
import {environmentCommonConfig} from 'components/app/services/environment/environment-common.config';
@Component({
  selector: 'ic-sidebar-filter',
  templateUrl: 'sidebar-filter.component.html'
})
export class SidebarFilterComponent implements OnChanges, OnDestroy {
  @Input() outsideClickEvent: boolean;
  @Input() allFilters!: AnalysisFilterModel[];
  @Input() entity: string | undefined;
  @Input() filterGroup!: AnalysisFilterGroupModel;
  schemaSelected: string = '';

  @Output() close = new EventEmitter();

  @Output() filterChange = new EventEmitter<AnalysisAppliedFiltersModel>();

  @Output() filterApply = new EventEmitter<{ [filter: string]: AppliedFilterValuesModel }>();

  @Output() inputFilters = new EventEmitter();
  @Output() showChooseTreeModal = new EventEmitter();
  eventValues: { [filter: string]: string } = {};

  filterGroupDescription!: string;

  filterValues: { [filter: string]: AppliedFilterValuesModel } = {};

  filterFields!: AnalysisFilterModel[];

  subscription: Subscription[] = [];
  isFilterChanged: boolean = false;
  originalFilterFields!: AnalysisFilterModel[];
  hasPopupSelections: boolean = false;

  private envConfig: { [key: string]: any }; // tslint:disable-line:no-any


  constructor(
    private analysisFilterService: AnalysisFiltersService,
    private environmentService: EnvironmentService,
    private cd: ChangeDetectorRef,
    private appliedFiltersService: AnalysisAppliedFiltersService
  ) {
    this.envConfig = this.environmentService.getEnvironment();
    this.appliedFiltersService.closeModel.subscribe((filters) => {
      this.filterFields.forEach((field) => {
        // @ts-ignore
        field.appliedValues = filters[field.name];
      });
      this.originalFilterFields = cloneDeep(this.filterFields);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const filterGroupChanged = changes.filterGroup && changes.filterGroup.currentValue;
    if (filterGroupChanged) {
      if (changes.filterGroup.currentValue.filters.findIndex((filter: { name: string; }) => filter.name === 'schema') !== -1) {
        let index = changes.filterGroup.currentValue.filters.findIndex((filter: { name: string; }) => filter.name === 'schema');
        if (this.entity === 'organization' && changes.filterGroup.currentValue.filters[index].appliedValues.is === 'GIPP') {
          this.schemaSelected = 'GIPP';
        }
      }
      this.filterGroupDescription = this.filterGroup.description || '';
      this.filterValues = {};
      this.eventValues = {};
      this.loadFilterOptions();
      this.setInterdependentValues();
    }
  }

  ngOnDestroy() {
    this.unsubscribeFromData();
  }

  isDepartmentAndOrgnameFilter() {
    return this.entity === 'department' && this.filterGroup.path === 'orgDeptList';
  }

  async onFilterValueChange(filter: AnalysisFilterModel, values: AppliedFilterValuesModel) {
    this.isFilterChanged = true;
    if (values.filter === 'schema') {
      this.schemaSelected = values.is as string;
    }
    const eventFilterName = filter.name;
    const eventFilter = <AnalysisFilterModel>this.filterFields.find(({ name }) => name === eventFilterName);
    const appliedValues: AppliedFilterValuesModel = <AppliedFilterValuesModel>pick(values, ['is', 'not']);
    eventFilter.appliedValues = appliedValues;
    this.filterValues[eventFilter.name] = appliedValues;

    const allShowOnFilter = this.allShowOnFilter(eventFilterName);
    const dependentFilters = this.allDependentToEventFilter(eventFilterName);
    const allFilterToUpdate = sortBy(uniqBy(allShowOnFilter.concat(dependentFilters), 'name'), ({ order }) => order);

    allFilterToUpdate.forEach((dependentFilter) => {
      // @ts-ignore
      let filtersToOmitDependents = environmentCommonConfig.analysis.filters.omitDependents[this.entity];
      let { showOn } = dependentFilter;
      if (!filtersToOmitDependents || (filtersToOmitDependents && !filtersToOmitDependents.includes(dependentFilter.name))) {
        const {name: dependentFilterName} = dependentFilter;
        dependentFilter.appliedValues = {};
        delete this.filterValues[dependentFilterName];
      }
      if (showOn) {
        let hidden = false;
        hidden = !(Object.keys(showOn).some((fltrName) => {
          // @ts-ignore
          return this.analysisFilterService.isMatching(this.filterValues, showOn, fltrName);
        }));
        dependentFilter.hidden = hidden;
      }
    });

    let filteredDependentFilters = allFilterToUpdate
      .filter((filteredDependentFilter) => {
        const { prefetch, dependsOn, source } = filteredDependentFilter;
        if (isString(source)) {
          if (!prefetch || dependsOn) return true;
          if (prefetch && !dependsOn) {
            filteredDependentFilter.appliedValues = this.analysisFilterService.getFilterDefaults(filteredDependentFilter);
            return false;
          }
        }
        return false;
      });
      if (!(this.entity === 'department' &&  filter.name === 'orgname')) {
        for (let filteredDependentFilter of filteredDependentFilters) {
          await this.getOptions(filteredDependentFilter);
        }
      }
    }

  onFilterInputChange(filter: AnalysisFilterModel, { value: eventValue }: { value: string }) {
    const { choice, dependsOn, name, prefetch, source } = filter;
    this.eventValues[name] = eventValue;
    let flag: boolean|undefined = false;
    if (name === 'funderLocation') flag = prefetch;
    if ((flag || !prefetch || dependsOn) && isString(source)) {
      if (choice === 'search') filter.filteredDataFound = 'NOT_FOUND';
      const subscription = this.analysisFilterService.requestFilterOptions(
        source,
        this.analysisFilterService.getFilterOptionsRequestParams(filter, eventValue, this.allFilters, this.filterValues)
      ).subscribe((options: FilterOptionModel[]) => this.updateFilter(filter, options));
      this.subscription.push(subscription);
    }
  }

  onFilterPastedChange(filter: AnalysisFilterModel, { value: eventValue }: { value: string }) {
    const { choice, dependsOn, name, prefetch, source } = filter;
    this.eventValues[name] = eventValue;

    if (
      !this.envConfig.feature.pasteFilterValues ||
      !this.analysisFilterService.shouldPostRequestFilterOptions(source,
        this.analysisFilterService.getFilterOptionsRequestParams(filter, eventValue, this.allFilters, this.filterValues))
    ) {
      return this.onFilterInputChange(arguments[0], arguments[1]);
    }
    if ((!prefetch || dependsOn) && isString(source)) {
      if (choice === 'search') filter.filteredDataFound = 'NOT_FOUND';
      const subscription = this.analysisFilterService.postRequestFilterOptions(
        source,
        this.analysisFilterService.getFilterOptionsRequestParams(filter, eventValue, this.allFilters, this.filterValues)
      ).subscribe((options: FilterOptionModel[]) => this.updateFilter(filter, options, true));
      this.subscription.push(subscription);
    }
  }

  onApply() {
    this.originalFilterFields = cloneDeep(this.filterFields);
    this.isFilterChanged = false;
    let appliedValue: string = '';
    let reputationValue = false;
    this.originalFilterFields.forEach((value) => {

      if (value.name === 'schema') {
        // @ts-ignore
        appliedValue = value.appliedValues?.is;
        value.source.map((source: { value: string; reputationGroup: boolean; }) => {
          if (source.value === appliedValue) {
            reputationValue = source.reputationGroup;

          }
        });
      }
    });
    // @ts-ignore
    this.filterValues['reputationGroup'] = reputationValue;
    this.filterApply.emit(this.filterValues);
    this.outsideClickEvent = false;
    this.closePanel();
  }

  onCancel() {
    this.outsideClickEvent = false;
    if (this.isFilterChanged) {
        this.filterFields.forEach((item) => {
          const originalItem = find(this.originalFilterFields, { name: item.name });

          if (originalItem) {
            item.appliedValues = originalItem.appliedValues;
            item.options = originalItem.options;
          }
        });
      }
      this.isFilterChanged = false;
      this.closePanel();
    }

  private unsubscribeFromData() {
    if (this.subscription) {
      this.subscription.forEach(subscription => subscription.unsubscribe());
    }
  }

  amendDependencies(filter: AnalysisFilterModel) {
    const dependencies = this.envConfig.analysis.filters.dependsOn[filter.name];

    if (!isUndefined(dependencies)) {
      filter.dependsOn = dependencies;
    }
  }

  amendGroupDependencies() {
    this.filterGroup.filters?.forEach(this.amendDependencies.bind(this));
  }

  checkForPopupSelections(filter: AnalysisFilterModel) {
    if (filter.choice === 'link') {
      this.hasPopupSelections = true;
    }
  }

  private loadFilterOptions() {
    this.hasPopupSelections = false;

    this.amendGroupDependencies();
    this.filterFields = this.filterGroup.filters || [];
    this.originalFilterFields = cloneDeep(this.filterFields);

    if (this.filterFields.length) {
      const flattenedFilters = this.analysisFilterService.flattenFilters(this.filterFields);
      const sourceObservables = flattenedFilters.map((filter) => {
        this.checkForPopupSelections(filter);
        return this.analysisFilterService.getFilterOptions(
          filter,
          this.analysisFilterService.getFilterOptionsRequestParams(
            filter,
            '',
            this.allFilters,
            this.filterValues
          )
        );
      });

      const subscription = forkJoin(sourceObservables)
        .subscribe((data: any[]) => { // tslint:disable-line:no-any
          flattenedFilters.forEach((filter, index) => {
            filter.options = data[index];
          });
          this.setPlaceHolder();
          this.cd.detectChanges();
        }, (error) => {
          console.error(error);
        });

      this.subscription.push(subscription);
    }
  }

  private setInterdependentValues() {
    const getInterDependentValue = (interDependentFiltersNames: string[]) => {
      return interDependentFiltersNames.reduce((acc, fltrName) => {
        const fltr = find(this.allFilters, { name: fltrName });

        if (fltr && this.analysisFilterService.isFilterApplied(fltr)) {
          acc = clone(fltr.appliedValues!) as AnalysisAppliedFiltersModel;
        }

        return acc;
      }, {} as AnalysisAppliedFiltersModel);
    };
    const applyValueOfInterDependent = (filter: AnalysisFilterModel) => {
      if (filter.interDependent) {
        const { name } = filter;
        const interDependentValue = getInterDependentValue(filter.interDependent);

        if (interDependentValue && (interDependentValue.is || interDependentValue.not)) {
          if (!isEqual(filter.appliedValues, interDependentValue)) {
            const event = assign(interDependentValue, { filter: name });

            this.onFilterValueChange(filter, event);
          }
        }
      }
    };

    this.filterFields.forEach(applyValueOfInterDependent);
  }

  private closePanel() {
    this.close.emit();
  }

  private setPlaceHolder() {
    this.analysisFilterService.flattenFilters(this.filterFields)
      .filter(({ choice, searchPlaceHolder }) => choice === 'search' && isPlainObject(searchPlaceHolder))
      .forEach((filter) => {
        const { dependsOn } = filter;
        if (dependsOn && dependsOn.length) {
          const dependsOnFilterName = dependsOn[0];
          const dependsOnFilter = this.filterFields.find(({ name }) => name === dependsOnFilterName) || this.allFilters.find(({ name }) => name === dependsOnFilterName);
          if (dependsOnFilter) {

            const { appliedValues } = dependsOnFilter;
            const value = appliedValues!.is || appliedValues!.not;
            if (!value && dependsOnFilter.options?.[0] && dependsOnFilter.noRequiredDeFaultValue) {
              filter.placeholder = (filter.searchPlaceHolder!)[dependsOnFilter.options?.[0].value as string];
            }
            if (value) {
              filter.placeholder = (filter.searchPlaceHolder!)[value as string];
            }
          }
        }
      });
  }

  private allShowOnFilter(eventFilterName: string) {
    const filterFields = this.filterFields
      .filter(({ name, showOn }) => {
        if (!showOn) return false;
        if (name === eventFilterName) return false;
        if (!Object.keys(showOn).includes(eventFilterName)) return false;
        return true;
      });
    return filterFields;
  }

  private allDependentToEventFilter(eventFilterName: string) {
    const collectDependentFilter = (filterName: string, intial = false) => {
      const filter = this.filterFields.find(({ name }) => name === filterName) as AnalysisFilterModel;
      let filters: AnalysisFilterModel[] = !intial ? [filter] : [];
      if (filter && filter.dependent) {
        filter.dependent.forEach(dependent => filters = filters.concat(collectDependentFilter(dependent)));
      }
      return filters;
    };
    return Array.from(new Set(collectDependentFilter(eventFilterName, true)));
  }

  private getOptions(filter: AnalysisFilterModel) {
    return new Promise<void>((resolve) => {
      const { choice, name, source } = filter;
      const eventValue = this.eventValues[name] || '';
      const subscription = this.analysisFilterService.requestFilterOptions(
        source,
        this.analysisFilterService.getFilterOptionsRequestParams(filter, eventValue, this.allFilters, this.filterValues)
      ).pipe(
        tap(() => { if (choice === 'search') filter.filteredDataFound = 'NOT_FOUND'; })
      ).subscribe((options: FilterOptionModel[]) => {
        this.updateFilter(filter, options);
        resolve();
      });
      this.subscription.push(subscription);
    });
  }

  private updateFilter(filter: AnalysisFilterModel, options: FilterOptionModel[], isPasted: boolean = false) {
    const { choice, name } = filter;
    const updatefilter: Partial<AnalysisFilterModel> = { options };
    if (choice === 'search') updatefilter.filteredDataFound = true;
    filter = assign(filter, updatefilter);
    if (choice === 'search') {
      if (isPasted) {
        filter.appliedValues = this.analysisFilterService.getMatchingIsOrNotValues(filter.appliedValues, options);
      } else {
        filter.appliedValues = this.analysisFilterService.getFilterDefaults(filter, filter.appliedValues);
      }
    } else {
      filter.appliedValues = this.analysisFilterService.getFilterDefaults(filter);
    }
    this.filterValues[name] = { ...filter.appliedValues };
    this.setPlaceHolder();
    this.cd.detectChanges();
  }

  showFilter({ choice, hidden, type, options }: AnalysisFilterModel) {
    if (choice === 'link') {
      return false;
    }
    if (!hidden) {
      if (type === 'list' && options!.length <= 1) return false;
      return true;
    }
    return false;
  }

  getDeptFilter() {
    return this.filterFields.find(filterField => this.shouldShowPopupButton(filterField));
  }

  shouldShowPopupButton(filter: AnalysisFilterModel) {
    const dependencies = filter.dependsOn;

    if (filter.choice === 'link' && !isUndefined(dependencies)) {
      return dependencies.some((dependsOn) => {
        return this.allFilters.some((allFilter) => {
          return allFilter.name === dependsOn &&
            !isUndefined(allFilter.appliedValues) &&
            !isUndefined(allFilter.appliedValues.is) &&
            allFilter.appliedValues.is.length > 0;
        });
      });
    }
    return false;
  }

  // tslint:disable-next-line:no-any
  emittedInputFilters(inputValues: any) {
    this.onCancel();
    this.inputFilters.emit({inputValues, schema : this.filterValues});
  }

}
